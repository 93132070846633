import BecomePartner from "../assets/images/home/becomepartner.webp";
import Careers from "../assets/images/home/careers.webp";
import HeaderImg from "../assets/images/about-us/clarivizby-benf/headerImg.webp";
import { ImageTextLayout, SEO } from "../components";
import React from "react";
import StoreLocator from "../assets/images/home/store-locator.webp";
import UrlConstants from "../constants/urlConstants";
import { NavigationStrings } from "../constants";

const ClearvizByEyegear = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.AboutUsOptions.CLEARVIZBYEYEGEAR);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Clearviz by Eyegear | Leading Eyewear and Lens Manufacturer",
    "description": "Clearviz by Eyegear – a trusted name in eyewear, offering the latest international lens technologies and styles. Partner with us and experience excellence in lens manufacturing.",
    "url": UrlLink
  };
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clearviz by Eyegear | Leading Eyewear and Lens Manufacturer"
        description="Clearviz by Eyegear – a trusted name in eyewear, offering the latest international lens technologies and styles. Partner with us and experience excellence in lens manufacturing."
        keywords="Clearviz, Eyegear, eyewear, lens manufacturer, lens technology, spectacles, retail partners, Hyderabad, careers"
        name="Clearviz by Eyegear"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="Lens Color header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-6 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clearviz By Eyegear
        </h1>
        <p className="text-base text-black leading-loose w-[98%]">
          Based in Hyderabad, Eyegear Optics India Pvt. Ltd has, for over 10
          years, introduced the market to internationally-inspired frame styles,
          spectacles, and sunglasses, ranging from classical to contemporary to
          futuristic. Sourced from quality manufacturers worldwide, Eyegear has
          become a trusted name in eyewear.
        </p>
      </div>
      <div className="mb-2 flex flex-col space-y-6 px-custom-padding">
        <h1 className="text-3xl text-primary font-bold">Eyegear</h1>
        <p className="text-base text-black leading-loose w-[98%]">
          Since 2020, Eyegear has expanded its services and solutions through
          2,400+ independent retail partners across tier 1, 2, and 3 cities,
          selling over half a million units per year. Starting January 2024,
          Eyegear established its state-of-the-art lens manufacturing unit in
          Hyderabad, investing heavily in the latest global machinery. This
          ensures that Indians nationwide have access to cutting-edge lens
          technology and unparalleled value.
        </p>
      </div>
      <div className="py-6 px-custom-padding">
        <button className="btn btn-secondary">
          <a href="https://www.eyegear.com/" target="_blank" rel="noreferrer">
            Find out more
          </a>
        </button>
      </div>
      <ImageTextLayout
        imageSrc={BecomePartner}
        title="Become a Clearviz Partner"
        description="As the leading manufacturer of spectacle lenses, Clearviz offers unparalleled quality, 
        innovation, and support to our business partners. By collaborating with us, you gain access to an 
        extensive range of premium lenses, cutting-edge technology, and expert insights, empowering you to 
        meet the diverse needs of your customers."
        description2="Experience the advantages of partnering with Clearviz and grow your business with 
        confidence. Join us today and achieve excellence together"
        buttonTxt="Find out more"
        reverse
      />
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
      <ImageTextLayout
        imageSrc={Careers}
        title="Careers at Clearviz"
        description="Join a dynamic and innovative eyewear company that values its employees and is 
        committed to fostering a positive, inclusive work environment. If you are passionate about eyewear 
        and seeking an exciting, upwardly mobile career opportunity, we invite you to apply!"
        buttonTxt="Send your CV"
        reverse
      />
    </section>
  );
};

export default ClearvizByEyegear;
