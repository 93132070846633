const NavigationStrings = Object.freeze({
  HOME: "/",
  CLEARVIZLENSES: "/clearviz-lenses",
  LensPortfolio: {
    RAPTVUE: "/clearviz-raptvue",
    VVIEW: "/clearviz-v-view",
    VISISTYLE: "/clearviz-visi-style",
    VISIFLEX: "/clearviz-visi-flex",
    DIGIFOCUS: "/clearviz-digi-focus",
    DRIVE: "/clearviz-drive",
    OFFICE: "/clearviz-office",
    FSV: "/clearviz-fsv",
    BIFOCALRX: "/clearviz-bifocal-rx",
    EGDIGITALPALS: "/eg-digital-pals",
  },
  LENSTECHNOLOGIES: "/clearviz-lens-technologies",
  LensCoatinOptions: {
    CASTLECOATING: "/clearviz-castle-coating",
    BLUEUV: "/clearviz-blue-uv-++",
    POTENSCOATING: "/clearviz-potens-coating",
  },
  LENSCOLOR: "/clearviz-lens-colors",
  LENSPERSONALIZATION: "/clearviz-lens-personalization",
  AboutUsOptions: {
    CLEARVIZBYEYEGEAR: "/about-clearviz-by-eyegear",
    LENSMANUFACTURINGUNIT: "/about-lens-manufacturing-unit",
  },
  CONTACTUS: "/contact-us",
  CAREERS: "/careers",
  PRIVACYPOLICY: "/privacy-policy",
  COOKIEPOLICY: "/cookie-policy",
});

export default NavigationStrings;
