import React from "react";
import HeaderImg from "../../assets/images/clearviz-lenses/lens-portfolio/fsv/headerImg.webp";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import { ImageTextLayout, SEO } from "../../components";
import FSV from "../../assets/images/clearviz-lenses/lens-portfolio/fsv/fsv.webp";
import FSVStandard from "../../assets/images/clearviz-lenses/lens-portfolio/fsv/fsv-standard.webp";
import UrlConstants from "../../constants/urlConstants";
import { NavigationStrings } from "../../constants";

const ClearvizFSV = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.LensPortfolio.FSV);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Clearviz FSV Lenses | Enhanced Vision with Full Surface Vision",
    "description": "Discover Clearviz FSV Lenses, offering reduced spherical aberrations, improved image quality, and a wider field of view for optimal vision clarity.",
    "url": UrlLink
  };
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clearviz FSV Lenses | Enhanced Vision with Full Surface Vision"
        description="Discover Clearviz FSV Lenses, offering reduced spherical aberrations, improved image quality, and a wider field of view for optimal vision clarity."
        keywords="Clearviz FSV, full surface vision, reduced spherical aberrations, wider field of view, vision correction, eyewear"
        name="Clearviz FSV Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[35vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={HeaderImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clearviz FSV
        </h1>
      </div>
      <div className="pb-8 flex flex-col space-y-4 px-custom-padding">
        {/* img */}
        <div className="pt-4 pb-8 px-custom-padding grid grid-cols-1 gap-8 md:grid-cols-2 place-items-center">
          <div className="px-6 flex flex-col gap-4">
            <h3 className="uppercase text-primary font-semibold text-lg">
              Clearviz FSV Lens
            </h3>
            <div>
              <img src={FSV} alt="FSV Lenses" className="w-[100%] h-[100%]" />
            </div>
          </div>
          <div className="px-6 flex flex-col gap-4">
            <h3 className="uppercase text-primary font-semibold text-lg">
              Standard FSV Lens
            </h3>
            <div>
              <img
                src={FSVStandard}
                alt="FSV Standard Lenses"
                className="w-[100%] h-[100%]"
              />
            </div>
          </div>
        </div>

        {/* Ideal Wearer */}
        <div className="py-8 px-custom-padding w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 md:justify-between">
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">Benefits</p>
            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Reduced Spherical Aberrations.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Improved Image Quality.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Lighter and Thinner.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Wider Field of View.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Enhanced Aesthetic Appeal.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default ClearvizFSV;
