import React from "react";
import HeaderImg from "../../assets/images/clearviz-lenses/lenstechnologies/headerImg.webp";
import Raptuve from "../../assets/images/home/raptuve.webp";
import StoreImg from "../../assets/images/home/store-locator.webp";

import { ImageTextLayout, SEO } from "../../components";
import LensTechnologyData from "./LensTechnologyData";
import UrlConstants from "../../constants/urlConstants";
import { NavigationStrings } from "../../constants";
const LensTechnologies = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.LENSTECHNOLOGIES);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Lens Technologies | Clearviz Advanced Optical Solutions",
    "description": "Explore Clearviz’s nine cutting-edge lens technologies that offer precise vision in all conditions. Learn about the advanced features we incorporate into our lenses.",
    "url": UrlLink
  };
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Lens Technologies | Clearviz Advanced Optical Solutions"
        description="Explore Clearviz’s nine cutting-edge lens technologies that offer precise vision in all conditions. Learn about the advanced features we incorporate into our lenses."
        keywords="Clearviz, lens technologies, optical solutions, vision enhancement, advanced lens features"
        name="Clearviz Lens Technologies"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="Lens Technologies header pic with girl smiling with specs on from back to front"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-6 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clearviz Technologies
        </h1>
        <p className="text-base text-black leading-loose w-[98%]">
          Clearviz offers, in all of our lenses, ideal combinations of the
          following nine world-class, cutting-edge technologies. Thus ensuring
          that your lenses provide you with uncompromisingly accurate vision, in
          all conditions.
        </p>

        {/* Technologies */}
        <div className="pt-6 md:mx-custom-padding">
          {LensTechnologyData.map((item, index) => (
            <div
              key={index}
              className={`grid grid-cols-1 md:grid-cols-8
              ${index % 2 === 0 ? "border" : "border-l border-r"} ${
                index === 7 && "border-b"
              } ${index % 2 !== 0 ? "bg-slate-400/10" : ""}
              `}
            >
              <div className="md:col-span-2 p-[0.85rem] border-r grid grid-cols-2 md:grid-cols-3 items-center justify-center gap-2">
                <div className="w-full h-full flex items-center justify-center">
                  <img
                    src={item.imgSrc}
                    alt={item.title}
                    className="md:col-span-1"
                  />
                </div>
                <div className="text-primary font-bold md:col-span-2">
                  {item.title}
                </div>
              </div>
              <div className="md:col-span-6 p-[0.85rem]">
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ImageTextLayout
        imageSrc={Raptuve}
        subtitle="Clearviz Raptvue"
        title="The most technologically advanced progressive lens, tailored for you."
        description="Clearviz Raptvue Lenses are made to fit the modern and 
        high-demand lifestyle of nowadays, where progressive wearers have a busy 
        schedule of work, leisure, indoor and outdoor activities."
        buttonTxt="Find out more"
        reverse
      />
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default LensTechnologies;
