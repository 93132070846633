import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AllRoutes } from "./constants";
import { HelmetProvider } from "react-helmet-async";

const helmetContext = {};
const App = () => {
  const route = createBrowserRouter(AllRoutes);
  return (
    <HelmetProvider context={helmetContext}>
      <RouterProvider router={route} />
    </HelmetProvider>
  );
};

export default App;
