import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  IoChevronDown,
  IoChevronForwardOutline,
  IoClose,
  IoMenu,
} from "react-icons/io5";
import { useScrollPosition } from "../hooks";
import { Link } from "react-router-dom";
import { NavigationStrings } from "../constants";
import { Accordion, AccordionSummary } from "@mui/material";

const Navbar = () => {
  const scrollPosition = useScrollPosition();
  const dropdownRef = useRef<any>(null);
  const dropdownAboutRef = useRef<any>(null);
  const [menuToggle, setMenuToggle] = useState(false);
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const [aboutusToggle, setAboutusToggle] = useState(false);
  const [subLensPortfolioToggle, setSubLensPortfolioToggle] = useState(false);
  const [subLensCoatingToggle, setSubLensCoatingToggle] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handlePortfolioChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const closeMenu = () => {
    setMenuToggle(false);
  };
  useLayoutEffect(() => {
    const isDesktopOrLaptop = window.matchMedia("(min-width: 810px)").matches;

    if (isDesktopOrLaptop && scrollPosition > 12) {
      setDropdownToggle(false);
      setAboutusToggle(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutsideAbout = (event: MouseEvent) => {
      if (
        dropdownAboutRef.current &&
        !dropdownAboutRef.current.contains(event.target as Node)
      ) {
        setAboutusToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideAbout);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideAbout);
    };
  }, []);

  return (
    <nav
      className={`bg-primary w-[100%] max-w-screen-2xl 
  mx-auto px-custom-padding py-custom-padding flex items-center relative
  ${scrollPosition > 60 ? "sticky top-0 z-10" : ""}`}
    >
      <div className="flex items-center justify-between w-full">
        <div className="relative w-[160px]">
          <Link
            to={NavigationStrings.HOME}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <div className="font-semibold text-2xl text-white">CLEARVIZ</div>
          </Link>
        </div>
        <ul className="hidden md:text-white md:flex align-center text-base space-x-6">
          <li className="hover:text-slate-400">
            <Link
              to={NavigationStrings.HOME}
              className="text-base"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Home
            </Link>
          </li>
          <li className="relative group transition-all" ref={dropdownRef}>
            <button
              onClick={() => {
                setDropdownToggle(!dropdownToggle);
                setSubLensPortfolioToggle(false);
                setSubLensCoatingToggle(false);
                setAboutusToggle(false);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className="hover:text-slate-400 w-full flex items-center justify-between"
            >
              <Link to={NavigationStrings.CLEARVIZLENSES}>Clearviz Lenses</Link>
              <IoChevronDown size={22} className="pl-1" />
            </button>
            {dropdownToggle && (
              <ul className="absolute right-[-10px] mt-3 w-[140%] bg-primary shadow-lg rounded-sm py-1 text-white z-20">
                <li className="text-tinybase relative">
                  <button
                    onClick={() => {
                      setSubLensPortfolioToggle(!subLensPortfolioToggle);
                      setSubLensCoatingToggle(false);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    className="w-full text-left p-3 flex items-center justify-between hover:bg-slate-400/30 "
                  >
                    Lens Portfolio
                    <IoChevronForwardOutline
                      color="white"
                      size={22}
                      className="pl-1"
                    />
                  </button>
                  {subLensPortfolioToggle && (
                    <ul className="absolute top-0 left-full mt-[0.015rem] ml-0 w-[110%] bg-primary shadow-lg rounded-sm py-1 text-white z-30">
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handlePortfolioChange("panel1")}
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<IoChevronDown color="white" />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <div className="text-white">Progressive</div>
                        </AccordionSummary>
                        <div className="bg-lightPrimary/70">
                          <Link
                            to={NavigationStrings.LensPortfolio.RAPTVUE}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                          >
                            Clearviz Raptvue
                          </Link>
                          <Link
                            to={NavigationStrings.LensPortfolio.VVIEW}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                          >
                            Clearviz V View
                          </Link>
                          <Link
                            to={NavigationStrings.LensPortfolio.VISISTYLE}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                          >
                            Clearviz Visi Style
                          </Link>
                          <Link
                            to={NavigationStrings.LensPortfolio.VISIFLEX}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                          >
                            Clearviz Visi Flex
                          </Link>
                          <Link
                            to={NavigationStrings.LensPortfolio.EGDIGITALPALS}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                          >
                            EG Digital Pals
                          </Link>
                        </div>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handlePortfolioChange("panel2")}
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<IoChevronDown color="white" />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <div className="text-white">BiFocal</div>
                        </AccordionSummary>
                        <div className="bg-lightPrimary/70">
                          <Link
                            to={NavigationStrings.LensPortfolio.BIFOCALRX}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                          >
                            Clearviz Bi Focal Rx
                          </Link>
                        </div>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handlePortfolioChange("panel3")}
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<IoChevronDown color="white" />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          <div className="text-white">Single Vision</div>
                        </AccordionSummary>
                        <div className="bg-lightPrimary/70">
                          <Link
                            to={NavigationStrings.LensPortfolio.FSV}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                          >
                            Clearviz FSV
                          </Link>
                        </div>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel4"}
                        onChange={handlePortfolioChange("panel4")}
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<IoChevronDown color="white" />}
                          aria-controls="panel4-content"
                          id="panel4-header"
                        >
                          <div className="text-white">Special Lens</div>
                        </AccordionSummary>
                        <div className="bg-lightPrimary/70">
                          <Link
                            to={NavigationStrings.LensPortfolio.DIGIFOCUS}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                          >
                            Clearviz Digi Focus
                          </Link>
                          <Link
                            to={NavigationStrings.LensPortfolio.DRIVE}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                          >
                            Clearviz Drive
                          </Link>
                          <Link
                            to={NavigationStrings.LensPortfolio.OFFICE}
                            onClick={() => {
                              setDropdownToggle(false);
                              setSubLensPortfolioToggle(false);
                            }}
                            className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                          >
                            Clearviz Office
                          </Link>
                        </div>
                      </Accordion>
                    </ul>
                  )}
                </li>
                <li className="text-tinybase p-3 hover:bg-slate-400/30">
                  <Link
                    to={NavigationStrings.LENSTECHNOLOGIES}
                    onClick={() => {
                      setDropdownToggle(false);
                      setSubLensPortfolioToggle(false);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    Lens Technologies
                  </Link>
                </li>
                <li className="text-tinybase relative">
                  <button
                    onClick={() => {
                      setSubLensCoatingToggle(!subLensCoatingToggle);
                      setSubLensPortfolioToggle(false);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    className="w-full text-left p-3 flex items-center 
                    justify-between hover:bg-slate-400/30 "
                  >
                    Lens Coatings & Protection
                    <IoChevronForwardOutline
                      color="white"
                      size={22}
                      className="pl-1"
                    />
                  </button>
                  {subLensCoatingToggle && (
                    <ul className="absolute top-0 left-full mt-[0.015rem] ml-0 w-[110%] bg-primary shadow-lg rounded-sm py-1 text-white z-30">
                      <Link
                        to={NavigationStrings.LensCoatinOptions.CASTLECOATING}
                        onClick={() => {
                          setDropdownToggle(false);
                          setSubLensCoatingToggle(false);
                        }}
                        className="w-max-content text-left rounded-sm 
                flex items-center px-2 py-2 hover:bg-slate-400/30 "
                      >
                        Castle Coating
                      </Link>
                      <Link
                        to={NavigationStrings.LensCoatinOptions.POTENSCOATING}
                        onClick={() => {
                          setDropdownToggle(false);
                          setSubLensCoatingToggle(false);
                        }}
                        className="w-max-content text-left rounded-sm 
                flex items-center px-2 py-2 hover:bg-slate-400/30 "
                      >
                        Potens Coating
                      </Link>
                      <Link
                        to={NavigationStrings.LensCoatinOptions.BLUEUV}
                        onClick={() => {
                          setDropdownToggle(false);
                          setSubLensCoatingToggle(false);
                        }}
                        className="w-max-content text-left rounded-sm 
                flex items-center px-2 py-2 hover:bg-slate-400/30 "
                      >
                        Blue UV ++
                      </Link>
                    </ul>
                  )}
                </li>
                <li className="text-tinybase p-3 hover:bg-slate-400/30">
                  <Link
                    to={NavigationStrings.LENSCOLOR}
                    onClick={() => {
                      setDropdownToggle(false);
                      setSubLensPortfolioToggle(false);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    Lens Tints
                  </Link>
                </li>
                <li className="text-tinybase p-3 hover:bg-slate-400/30">
                  <Link
                    to={NavigationStrings.LENSPERSONALIZATION}
                    onClick={() => {
                      setDropdownToggle(false);
                      setSubLensPortfolioToggle(false);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    Lens Personalization
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li
            className="relative group transition-all hover:text-slate-400"
            ref={dropdownAboutRef}
          >
            <button
              onClick={() => {
                setAboutusToggle(!aboutusToggle);
                setDropdownToggle(false);
              }}
              className="hover:text-slate-400 w-full flex items-center justify-between"
            >
              About Us
              <IoChevronDown size={22} className="pl-1" />
            </button>
            {aboutusToggle && (
              <ul className="absolute left-0  mt-2 w-[240%] bg-primary shadow-lg rounded-sm py-1 text-white z-20">
                <Link
                  to={NavigationStrings.AboutUsOptions.CLEARVIZBYEYEGEAR}
                  onClick={() => {
                    setDropdownToggle(false);
                    setAboutusToggle(false);
                    setSubLensPortfolioToggle(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  className="w-max-content text-left rounded-sm 
                flex items-center px-2 py-2 hover:bg-slate-400/30 "
                >
                  Clearviz by Eyegear
                </Link>
                {/* <Link
                  to={NavigationStrings.AboutUsOptions.LENSMANUFACTURINGUNIT}
                  onClick={() => {
                    setDropdownToggle(false);
                    setAboutusToggle(false);
                    setSubLensPortfolioToggle(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  className="w-max-content text-left rounded-sm 
                flex items-center px-2 py-2 hover:bg-slate-400/30 "
                >
                  Lens Manufacturing Unit
                </Link> */}
              </ul>
            )}
          </li>
          <li className="hover:text-slate-400">
            <Link
              to={NavigationStrings.CONTACTUS}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Contact Us
            </Link>
          </li>
        </ul>
        <div className="md:hidden">
          <button
            onClick={() => setMenuToggle(!menuToggle)}
            className="transition ease-in-out delay-150"
          >
            {menuToggle ? (
              <IoClose color="white" size={26} />
            ) : (
              <IoMenu color="white" size={26} />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {menuToggle && (
          <ul
            className="z-10 w-full absolute top-full left-0 min-h-[92vh] max-h-[calc(100vh-8vh)] overflow-auto bg-primary
          p-custom-padding text-white space-y-6 md:hidden"
          >
            <li className="flex">
              <Link
                to={NavigationStrings.HOME}
                className="text-left px-2 py-2  rounded-sm hover:bg-slate-400/30"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  closeMenu();
                }}
              >
                Home
              </Link>
            </li>
            <li className="text-tinybase relative" ref={dropdownRef}>
              <button
                onClick={() => {
                  setDropdownToggle(!dropdownToggle);
                  setSubLensPortfolioToggle(false);
                  setSubLensCoatingToggle(false);
                  setAboutusToggle(false);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className="w-max-content text-left rounded-sm flex items-center px-2 py-2 hover:bg-slate-400/30 "
              >
                <p>
                  <Link to={NavigationStrings.CLEARVIZLENSES}>
                    Clearviz Lenses
                  </Link>
                </p>
                <IoChevronDown size={22} className="pl-1" />
              </button>
              {dropdownToggle && (
                <ul className="w-[50%] ml-2 mt-1 bg-primary shadow-lg rounded-sm py-1 text-white">
                  <li className="relative">
                    <button
                      onClick={() => {
                        setSubLensPortfolioToggle(!subLensPortfolioToggle);
                        setSubLensCoatingToggle(false);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className="w-full text-left px-4 py-2 flex items-center justify-between hover:bg-slate-400/30"
                    >
                      Lens Portfolio
                      <IoChevronForwardOutline
                        color="white"
                        size={22}
                        className="pl-1"
                      />
                    </button>
                    {subLensPortfolioToggle && (
                      <ul className="absolute top-0 left-full w-full mt-0 ml-[0.015rem] bg-primary shadow-lg rounded-sm py-1 text-white z-50">
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handlePortfolioChange("panel1")}
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<IoChevronDown color="white" />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <div className="text-white">Progressive</div>
                          </AccordionSummary>
                          <div className="bg-lightPrimary/70">
                            <Link
                              to={NavigationStrings.LensPortfolio.RAPTVUE}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                            >
                              Clearviz Raptvue
                            </Link>
                            <Link
                              to={NavigationStrings.LensPortfolio.VVIEW}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                            >
                              Clearviz V View
                            </Link>
                            <Link
                              to={NavigationStrings.LensPortfolio.VISISTYLE}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                            >
                              Clearviz Visi Style
                            </Link>
                            <Link
                              to={NavigationStrings.LensPortfolio.VISIFLEX}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                            >
                              Clearviz Visi Flex
                            </Link>
                            <Link
                              to={NavigationStrings.LensPortfolio.EGDIGITALPALS}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                            >
                              EG Digital Pals
                            </Link>
                          </div>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel2"}
                          onChange={handlePortfolioChange("panel2")}
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<IoChevronDown color="white" />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <div className="text-white">BiFocal</div>
                          </AccordionSummary>
                          <div className="bg-lightPrimary/70">
                            <Link
                              to={NavigationStrings.LensPortfolio.BIFOCALRX}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                            >
                              Clearviz Bi Focal Rx
                            </Link>
                          </div>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel3"}
                          onChange={handlePortfolioChange("panel3")}
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<IoChevronDown color="white" />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                          >
                            <div className="text-white">Single Vision</div>
                          </AccordionSummary>
                          <div className="bg-lightPrimary/70">
                            <Link
                              to={NavigationStrings.LensPortfolio.FSV}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                            >
                              Clearviz FSV
                            </Link>
                          </div>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel4"}
                          onChange={handlePortfolioChange("panel4")}
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<IoChevronDown color="white" />}
                            aria-controls="panel4-content"
                            id="panel4-header"
                          >
                            <div className="text-white">Special Lens</div>
                          </AccordionSummary>
                          <div className="bg-lightPrimary/70">
                            <Link
                              to={NavigationStrings.LensPortfolio.DIGIFOCUS}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                            >
                              Clearviz Digi Focus
                            </Link>
                            <Link
                              to={NavigationStrings.LensPortfolio.DRIVE}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                            >
                              Clearviz Drive
                            </Link>
                            <Link
                              to={NavigationStrings.LensPortfolio.OFFICE}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensPortfolioToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              className="w-max-content text-left rounded-sm 
                flex items-center p-3 w-full text-white hover:bg-slate-400/30 "
                            >
                              Clearviz Office
                            </Link>
                          </div>
                        </Accordion>
                      </ul>
                    )}
                  </li>
                  <li className="px-4 py-2 hover:bg-slate-400/30">
                    <Link
                      to={NavigationStrings.LENSTECHNOLOGIES}
                      onClick={() => {
                        setDropdownToggle(false);
                        setSubLensPortfolioToggle(false);
                        closeMenu();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Lens Technologies
                    </Link>
                  </li>
                  <li className="text-tinybase relative">
                    <button
                      onClick={() => {
                        setSubLensCoatingToggle(!subLensCoatingToggle);
                        setSubLensPortfolioToggle(false);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className="w-full text-left px-4 py-2 flex items-center
                     justify-between hover:bg-slate-400/30"
                    >
                      Lens Coatings & Protection
                      <IoChevronForwardOutline
                        color="white"
                        size={22}
                        className="pl-1"
                      />
                      {subLensCoatingToggle && (
                        <ul className="absolute top-0 left-full w-full mt-0 ml-[0.015rem] bg-primary shadow-lg rounded-sm py-1 text-white">
                          <li className="text-tinybase px-4 py-2 hover:bg-slate-400/30">
                            <Link
                              to={
                                NavigationStrings.LensCoatinOptions
                                  .CASTLECOATING
                              }
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensCoatingToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              Castle Coating
                            </Link>
                          </li>
                          <li className="text-tinybase px-4 py-2 hover:bg-slate-400/30">
                            <Link
                              to={
                                NavigationStrings.LensCoatinOptions
                                  .POTENSCOATING
                              }
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensCoatingToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              Potens Coating
                            </Link>
                          </li>
                          <li className="text-tinybase px-4 py-2 hover:bg-slate-400/30">
                            <Link
                              to={NavigationStrings.LensCoatinOptions.BLUEUV}
                              onClick={() => {
                                setDropdownToggle(false);
                                setSubLensCoatingToggle(false);
                                closeMenu();
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              Blue UV ++
                            </Link>
                          </li>
                        </ul>
                      )}
                    </button>
                  </li>
                  <li className="px-4 py-2 hover:bg-slate-400/30">
                    <Link
                      to={NavigationStrings.LENSCOLOR}
                      onClick={() => {
                        setDropdownToggle(false);
                        setSubLensPortfolioToggle(false);
                        closeMenu();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Lens Tints
                    </Link>
                  </li>
                  <li className="px-4 py-2 hover:bg-slate-400/30">
                    <Link
                      to={NavigationStrings.LENSPERSONALIZATION}
                      onClick={() => {
                        setDropdownToggle(false);
                        setSubLensPortfolioToggle(false);
                        closeMenu();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Lens Personalization
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="text-tinybase relative" ref={dropdownAboutRef}>
              <button
                onClick={() => {
                  setAboutusToggle(!aboutusToggle);
                  setDropdownToggle(false);
                }}
                className="w-max-content text-left rounded-sm flex items-center px-2 py-2 hover:bg-slate-400/30 "
              >
                About Us
                <IoChevronDown size={22} className="pl-1" />
              </button>
              {aboutusToggle && (
                <ul className="w-[60%] ml-2 mt-1 bg-primary shadow-lg rounded-sm py-1 text-white">
                  <li className="px-4 py-2 hover:bg-slate-400/30">
                    <Link
                      to={NavigationStrings.AboutUsOptions.CLEARVIZBYEYEGEAR}
                      onClick={() => {
                        setDropdownToggle(false);
                        setSubLensPortfolioToggle(false);
                        setAboutusToggle(false);
                        closeMenu();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Clearviz by Eyegear
                    </Link>
                  </li>
                  {/* <li className="px-4 py-2 hover:bg-slate-400/30">
                    <Link
                      to={
                        NavigationStrings.AboutUsOptions.LENSMANUFACTURINGUNIT
                      }
                      onClick={() => {
                        setDropdownToggle(false);
                        setSubLensPortfolioToggle(false);
                        setAboutusToggle(false);
                        closeMenu();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Lens Manufacturing Unit
                    </Link>
                  </li> */}
                </ul>
              )}
            </li>
            <li className="flex">
              <Link
                to={NavigationStrings.CONTACTUS}
                className="text-left px-2 py-2 rounded-sm hover:bg-slate-400/30"
                onClick={closeMenu}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
