import React from "react";
import HeaderImg from "../../assets/images/clearviz-lenses/lens-portfolio/egdigitalpal/headerImg.webp";
import EgDigital20 from "../../assets/images/clearviz-lenses/lens-portfolio/egdigitalpal/EG Digital 20.webp";
import EgDigital10 from "../../assets/images/clearviz-lenses/lens-portfolio/egdigitalpal/EG Digital 10.webp";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import { ImageTextLayout, SEO } from "../../components";
import UrlConstants from "../../constants/urlConstants";
import { NavigationStrings } from "../../constants";

const EgDigitalPAL = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.LensPortfolio.EGDIGITALPALS);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "EG Digital PALs | Advanced Progressive Lenses",
    "description": "Discover EG Digital PALs with advanced digital surface technology. Offering precision, clarity, and comfort for progressive wearers in a digital world.",
    "url": UrlLink
  };
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="EG Digital PALs | Advanced Progressive Lenses"
        description="Discover EG Digital PALs with advanced digital surface technology. Offering precision, clarity, and comfort for progressive wearers in a digital world."
        keywords="EG Digital PALs, progressive lenses, digital lenses, vision correction, eyewear"
        name="EG Digital PALs"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[35vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={HeaderImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          EG Digital PALs
        </h1>
      </div>
      {/* img & benefits */}
      <div className="px-custom-padding pb-8 grid grid-cols-1 gap-12 md:grid-cols-2">
        <div className="px-6 flex flex-col gap-8">
          {/* img 2.0 */}
          <div className="flex flex-col gap-4">
            <h3 className="text-2xl font-bold text-primary">EG DIGITAL 2.0</h3>
            <div>
              <img
                src={EgDigital20}
                alt="EgDigital 2.0"
                className="w-[100%] h-[100%]"
              />
            </div>
          </div>
          {/* benefits 2.0 */}
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">
              EG DIGITAL 2.0 Benefits
            </p>

            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Advanced digital surface technology with extreme precision and
                wider field of vision.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Natural color perception.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                End to end clarity, Fast focus and good dynamic vision all day
                long.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Thin, flat and light weight lenses.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Natural posture in any activities.
              </li>
            </ul>
          </div>
          {/* MFH 2.0 */}
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">
              MFH (Minimum Fitting Height)
            </p>
            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                16 mm | 18 mm | 20 mm
              </li>
            </ul>
          </div>
        </div>

        <div className="px-6 flex flex-col gap-8">
          {/* img 1.0 */}
          <div className="flex flex-col gap-4">
            <h3 className="text-2xl font-bold text-primary">EG DIGITAL 1.0</h3>
            <div>
              <img
                src={EgDigital10}
                alt="EgDigital 1.0"
                className="w-[100%] h-[100%]"
              />
            </div>
          </div>
          {/* benefits 1.0 */}
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">
              EG DIGITAL 1.0 Benefits
            </p>
            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Digital surface technology.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Up to 20% wide field of vision compared to regular Pal’s.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Better peripheral vision and less distortion.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Natural image perception.
              </li>
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                Thin and light weight lenses.
              </li>
            </ul>
          </div>
          {/* MFH 1.0 */}
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">
              MFH (Minimum Fitting Height)
            </p>
            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc ml-2 text-base text-black  w-full md:w-[98%]">
                16 mm | 18 mm | 20 mm
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default EgDigitalPAL;
