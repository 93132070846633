import {
  Careers,
  BlueUV,
  CastleCoating,
  ClearvizByEyegear,
  ClearvizLenses,
  ContactUs,
  CookiePolicy,
  ErrorPage,
  Home,
  LensColours,
  LensManufacturingUnit,
  LensPersonalization,
  LensTechnologies,
  PotensCoating,
  PrivacyPolicy,
} from "../pages";

import { Layout } from "../components";
import {
  BiFocalRx,
  ClearvizDigiFocus,
  ClearvizDrive,
  ClearvizFSV,
  ClearvizOffice,
  ClearvizRaptuve,
  ClearvizVView,
  ClearvizVisiFlex,
  ClearvizVisiStyle,
  EGDigitalPAL,
} from "../pages/LensPortfolio";
import NavigationStrings from "./NavigationStrings";

interface IRouteComponents {
  path: string;
  element: JSX.Element;
  errorElement?: JSX.Element;
  children?: any;
}

const routePaths = Object.freeze({
  home: NavigationStrings.HOME,
  clearvizlenses: NavigationStrings.CLEARVIZLENSES,
  raptuve: NavigationStrings.LensPortfolio.RAPTVUE,
  vview: NavigationStrings.LensPortfolio.VVIEW,
  contactus: NavigationStrings.CONTACTUS,
  visistyle: NavigationStrings.LensPortfolio.VISISTYLE,
  visiflex: NavigationStrings.LensPortfolio.VISIFLEX,
  digifocus: NavigationStrings.LensPortfolio.DIGIFOCUS,
  drive: NavigationStrings.LensPortfolio.DRIVE,
  office: NavigationStrings.LensPortfolio.OFFICE,
  fsv: NavigationStrings.LensPortfolio.FSV,
  bifocalrx: NavigationStrings.LensPortfolio.BIFOCALRX,
  egdigitalpal: NavigationStrings.LensPortfolio.EGDIGITALPALS,
  clearvizByEyegear: NavigationStrings.AboutUsOptions.CLEARVIZBYEYEGEAR,
  lensmanufacturingunit: NavigationStrings.AboutUsOptions.LENSMANUFACTURINGUNIT,
  lenstechnologies: NavigationStrings.LENSTECHNOLOGIES,
  castlecoating: NavigationStrings.LensCoatinOptions.CASTLECOATING,
  castlebluecoating: NavigationStrings.LensCoatinOptions.BLUEUV,
  potenscoating: NavigationStrings.LensCoatinOptions.POTENSCOATING,
  lenscolor: NavigationStrings.LENSCOLOR,
  lenspersonalization: NavigationStrings.LENSPERSONALIZATION,
  aboutusbyeyegear: NavigationStrings.AboutUsOptions.CLEARVIZBYEYEGEAR,
  privacypolicy: NavigationStrings.PRIVACYPOLICY,
  cookiepolicy: NavigationStrings.COOKIEPOLICY,
  careers: NavigationStrings.CAREERS,
});

const AllRoutes: Array<IRouteComponents> = [
  {
    path: routePaths.home,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        index: true,
        path: routePaths.clearvizlenses,
        element: <ClearvizLenses />,
      },
      {
        index: true,
        path: routePaths.raptuve,
        element: <ClearvizRaptuve />,
      },
      {
        index: true,
        path: routePaths.vview,
        element: <ClearvizVView />,
      },
      {
        index: true,
        path: routePaths.contactus,
        element: <ContactUs />,
      },
      {
        path: routePaths.visistyle,
        element: <ClearvizVisiStyle />,
      },
      {
        index: true,
        path: routePaths.visiflex,
        element: <ClearvizVisiFlex />,
      },
      {
        index: true,
        path: routePaths.digifocus,
        element: <ClearvizDigiFocus />,
      },
      {
        index: true,
        path: routePaths.drive,
        element: <ClearvizDrive />,
      },
      {
        index: true,
        path: routePaths.office,
        element: <ClearvizOffice />,
      },
      {
        index: true,
        path: routePaths.fsv,
        element: <ClearvizFSV />,
      },
      {
        index: true,
        path: routePaths.bifocalrx,
        element: <BiFocalRx />,
      },
      {
        index: true,
        path: routePaths.egdigitalpal,
        element: <EGDigitalPAL />,
      },
      {
        index: true,
        path: routePaths.clearvizByEyegear,
        element: <ClearvizByEyegear />,
      },
      {
        index: true,
        path: routePaths.lensmanufacturingunit,
        element: <LensManufacturingUnit />,
      },
      {
        path: routePaths.lenstechnologies,
        element: <LensTechnologies />,
      },
      {
        index: true,
        path: routePaths.castlecoating,
        element: <CastleCoating />,
      },
      {
        index: true,
        path: routePaths.castlebluecoating,
        element: <BlueUV />,
      },
      {
        index: true,
        path: routePaths.potenscoating,
        element: <PotensCoating />,
      },
      {
        index: true,
        path: routePaths.lenscolor,
        element: <LensColours />,
      },
      {
        index: true,
        path: routePaths.lenspersonalization,
        element: <LensPersonalization />,
      },
      {
        index: true,
        path: routePaths.privacypolicy,
        element: <PrivacyPolicy />,
      },
      {
        index: true,
        path: routePaths.cookiepolicy,
        element: <CookiePolicy />,
      },
      {
        index: true,
        path: routePaths.careers,
        element: <Careers />,
      },
    ],
    errorElement: <ErrorPage />,
  },
];

export default AllRoutes;
