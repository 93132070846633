import React from "react";
import { Navbar } from "../components";
import { NavigationStrings } from "../constants";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <>
      <Navbar />
      <div className="h-[92vh] w-full flex flex-col justify-center items-center space-y-4 text-center p-4">
        <h2 className="text-primary text-2xl font-semibold w-[90%] md:w-[50%] mx-auto">
          Oh No! Page you are looking for is under-construction or not available
          at the moment.
        </h2>
        <div className="text-slate-800 font-medium text-lg">
          Go to{" "}
          <span>
            <Link
              className="text-primary hover:font-semibold"
              to={NavigationStrings.HOME}
            >
              Home
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default Error;
