import React from "react";
import HeaderImg from "../assets/images/home/headerImg.webp";
import Raptuve from "../assets/images/home/raptuve.webp";
import VView from "../assets/images/home/vview.webp";
import VisiStyle from "../assets/images/home/visistyle.webp";
import StoreLocator from "../assets/images/home/store-locator.webp";
import Careers from "../assets/images/home/careers.webp";
import BecomePartner from "../assets/images/home/becomepartner.webp";
import LensCatalog from "../assets/images/home/lenscatalog.webp";
import { useNavigate } from "react-router-dom";
import { NavigationStrings } from "../constants";
import { ImageTextLayout, SEO } from "../components";
import UrlConstants from "../constants/urlConstants";

const Home = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.HOME);
  const navigate = useNavigate();
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Clearviz Lenses | Advanced Optical Solutions",
    "description": "Explore Clearviz Lenses - the leading provider of advanced optical solutions in India. Unmatched clarity, precision, and quality designed for all your visual needs.",
    "url": UrlLink
  };
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clearviz Lenses | Advanced Optical Solutions"
        description="Explore Clearviz Lenses - the leading provider of advanced optical solutions in India. Unmatched clarity, precision, and quality designed for all your visual needs."
        keywords="Clearviz, lenses, optical solutions, progressive lenses, digital lenses, eyewear, vision correction, store locator, careers"
        name="Clearviz"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[35vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={HeaderImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-10"></div>
        <div className="z-1 absolute inset-0 flex items-center justify-start py-1 px-2 small:px-custom-padding">
          <div className="w-3/6 mlg:w-3/6 mlg:pr-custom-padding flex flex-col xs:gap-2">
            <h1 className="font-bold text-white xs:text-xl small:text-2xl md:text-3xl mlg:text-4xl">
              <div className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.9)] uppercase">
                INTRODUCING
              </div>
              <div className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.9)] uppercase">
                CLEARVIZ LENSES
              </div>
            </h1>
            <p
              className="text-balance text-white 
            text-tinybase xs:text-base small:text-md md:text-2xl
            drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] leading-snug 
            w-[83%] xs:w-[85%] small:w-[88%]
            font-light text-border xxs:uppercase"
            >
              The latest lens technology with superior quality, unparalleled
              precision and unmatched value!
            </p>
          </div>
        </div>
      </div>
      <div className="py-8 flex flex-col space-y-6 px-custom-padding">
        <p className="text-base text-black leading-loose w-[98%]">
          Experience the future of visual clarity with Clearviz Lenses, the
          epitome of advanced optical solutions tailored to meet all your visual
          requirements. Crafted through the most rigorous processes, our lenses
          reflect the professional pride and precision that define Clearviz as
          the market leader in India.
        </p>
        <p className="text-base text-black leading-loose w-[98%]">
          Our lenses are engineered to deliver exceptional visual acuity,
          allowing you to perceive the world with unmatched clarity and
          confidence. Leveraging the latest advancements in lens technology,
          Clearviz ensures you experience superior precision, reliability, and
          clarity.
        </p>
        <p className="text-base text-black leading-loose w-[95%]">
          Manufactured using precision engineering, international-quality
          materials, and cutting-edge equipment, our lenses offer unparalleled
          accuracy, making them the ultimate solution for your vision correction
          needs. Clearviz lenses provide consistent, crystal-clear performance
          under any condition, enhancing your vision and improving your quality
          of life.
        </p>
        <p className="text-base text-black leading-loose w-[95%]">
          Welcome to the world of Clearviz. Elevate your vision with the latest
          in lens technology and experience the unmatched superiority that only
          Clearviz can deliver.
        </p>
        <div>
          <button
            className="btn btn-secondary"
            onClick={(e: any) => {
              navigate(NavigationStrings.CLEARVIZLENSES);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Find out more
          </button>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={Raptuve}
        subtitle="Clearviz Raptvue"
        title="The most technologically advanced progressive lens, tailored for you."
        description="Clearviz Raptvue Lenses are made to fit the modern and 
        high-demand lifestyle of nowadays, where progressive wearers have a busy 
        schedule of work, leisure, indoor and outdoor activities."
        buttonTxt="Find out more"
        reverse
      />
      <ImageTextLayout
        imageSrc={VView}
        subtitle="Clearviz V View"
        title="Ground-breaking technology to ensure impeccable visual quality for you"
        description="Clearviz V View lenses are for Progressive users who have a high usage of 
        digital devices for long hours and are looking for quality and comfort. Clearviz V View lenses 
        provide a smoother and quicker transition as well as transitioning between digital devices and objects 
        between the near and intermediate zones."
        buttonTxt="Find out more"
        reverse
      />
      <ImageTextLayout
        imageSrc={VisiStyle}
        subtitle="Clearviz Visi Style"
        title="Optimised to ensure the best natural vision for you"
        description="Clearviz Visi Style lenses for progressive lens wearers who are searching for a 
        balance between quality, performance and comfort.  Clearviz Visi Style lenses offers effective and 
        comfortable vision in all fields, improved image stability, and reduced swim effect."
        buttonTxt="Find out more"
        reverse
      />
      {/* Video Container */}
      {/* <div className="max-w-screen-2xl mx-auto p-custom-padding flex flex-col-reverse md:flex-col gap-2 md:gap-12">
        <div className="flex flex-col gap-2">
          <h3
            className={
              "text-3xl md:text-45pt font-normal text-black capitalize md:leading-[2.55rem]"
            }
          >
            Clearviz Lens Manufacturing:
          </h3>
          <h2 className="text-2xl leading-tight md:text-45pt font-bold text-primary md:leading-[2.55rem]">
            How we do it
          </h2>
        </div>
        <div className="w-full md:w-3/4 mx-auto h-[280px] md:h-[440px] bg-[#d3d3d3] flex justify-center items-center rounded-sm ">
          Video
        </div>
      </div> */}
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
         buttonTxt="Call Customer Service"
         timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"      
        reverse
      />
      <ImageTextLayout
        imageSrc={Careers}
        title="Careers at Clearviz"
        description="Join a dynamic and innovative eyewear company that values its employees and is 
        committed to fostering a positive, inclusive work environment. If you are passionate about eyewear 
        and seeking an exciting, upwardly mobile career opportunity, we invite you to apply!"
        buttonTxt="Send your CV"
        reverse
      />
      <ImageTextLayout
        imageSrc={BecomePartner}
        title="Become a Clearviz Partner"
        description="As the leading manufacturer of spectacle lenses, Clearviz offers unparalleled quality, 
        innovation, and support to our business partners. By collaborating with us, you gain access to an 
        extensive range of premium lenses, cutting-edge technology, and expert insights, empowering you to 
        meet the diverse needs of your customers."
        description2="Experience the advantages of partnering with Clearviz and grow your business with 
        confidence. Join us today and achieve excellence together"
        buttonTxt="Find out more"
        reverse
      />
      <div className="mx-custom-padding my-[2.45rem] bg-primary/20 pb-[2.45rem]">
        <ImageTextLayout
          imageSrc={LensCatalog}
          title="Latest Clearviz Lens Catalog"
          description="Discover the latest innovations in lens technology with our newest catalog. 
          As the market leader in India, Clearviz is dedicated to providing top-quality lenses that cater to 
          the diverse needs of our customers. Our catalog showcases an extensive range of products, featuring cutting-edge 
          optical solutions and the latest advancements in lens design"
          showInputs={true}
          buttonTxt="Submit to Download"
          reverse
        />
      </div>
    </section>
  );
};

export default Home;
