import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { NavigationStrings } from "../constants";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";

interface ImageTextLayoutProps {
  imageSrc: string;
  subtitle?: string;
  title: string;
  description: string;
  description2?: string;
  buttonTxt?: string;
  reverse?: boolean;
  showInputs?: boolean;
  timings?: string;
}

const ImageTextLayout: React.FC<ImageTextLayoutProps> = ({
  imageSrc,
  subtitle,
  title,
  description,
  description2,
  buttonTxt,
  reverse,
  showInputs,
  timings,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const catalogSchema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    mobile: yup
      .string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .required("Mobile is required"),
    designation: yup.string().required("Designation is required"),
    organization: yup.string().required("Organization is required"),
  });

  const handleSubmit = async (values: any, { resetForm }: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "access_key",
      process.env.REACT_APP_WEB3FORMS_ACCESS_KEY as string
    );
    for (const key in values) {
      formData.append(key, values[key]);
    }
    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);
    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());
    setLoading(false);
    if (res.success) {
      toast.success(
        "Thank You. Our Lens Catalog will be emailed to your email address."
      );
      resetForm();
      console.log("Form values:", values);
    } else {
      console.error("Submission failed:", res);
      console.log("Form values:", values);
      toast.error("There was an error submitting the form. Please try again.");
    }
  };

  const openDialPad = (number: string) => {
    const callTo = `tel:${number}`;
    window.location.href = callTo;
  };
  const btnClickEventMethod = (
    e: React.FormEvent<HTMLFormElement>,
    buttonTxt: string,
    title: string,
    subtitle?: string
  ) => {
    if (
      subtitle?.toLocaleLowerCase() === "Clearviz Raptvue".toLocaleLowerCase()
    ) {
      navigate(NavigationStrings.LensPortfolio.RAPTVUE);
    } else if (
      subtitle?.toLocaleLowerCase() === "Clearviz V View".toLocaleLowerCase()
    ) {
      navigate(NavigationStrings.LensPortfolio.VVIEW);
    } else if (
      subtitle?.toLocaleLowerCase() ===
      "Clearviz Visi Style".toLocaleLowerCase()
    ) {
      navigate(NavigationStrings.LensPortfolio.VISISTYLE);
    } else if (
      subtitle?.toLocaleLowerCase() ===
      "Clearviz Lens Manufacturing".toLocaleLowerCase()
    ) {
      navigate(NavigationStrings.AboutUsOptions.LENSMANUFACTURINGUNIT);
    } else if (
      title.toLocaleLowerCase() ===
      "Become a Clearviz Partner".toLocaleLowerCase()
    ) {
      navigate(NavigationStrings.CLEARVIZLENSES);
    } else if (
      buttonTxt.toLocaleLowerCase() === "Send your CV".toLocaleLowerCase()
    ) {
      navigate(NavigationStrings.CAREERS);
    } else if (
      title.toLocaleLowerCase() === "Clearviz Technologies".toLocaleLowerCase()
    ) {
      navigate(NavigationStrings.LENSTECHNOLOGIES);
    } else if (
      title.toLocaleLowerCase() === "Clearviz Coatings".toLocaleLowerCase()
    ) {
      navigate(NavigationStrings.LensCoatinOptions.CASTLECOATING);
    } else if (
      title.toLocaleLowerCase() ===
      "Clearviz Personalization".toLocaleLowerCase()
    ) {
      navigate(NavigationStrings.LENSPERSONALIZATION);
    } else if (
      buttonTxt.toLocaleLowerCase() ===
      "Call Customer Service".toLocaleLowerCase()
    ) {
      console.log("Clicked");
      openDialPad("+91 - 8106165888");
    }
  };
  return (
    <section className="max-w-screen-2xl mx-auto px-custom-padding pt-[2.45rem]">
      <div
        className={`
          flex flex-col md:flex-row
          items-start rounded-sm
          gap-6
          md:gap-12
          ${reverse ? "md:flex-row-reverse" : ""}
        `}
      >
        <div className="w-full md:w-1/3">
          <img
            src={imageSrc}
            alt={title}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="w-full md:w-2/3 flex flex-col gap-6">
          <div className="flex flex-col md:gap-2">
            <h3
              className={`${
                subtitle
                  ? "text-xl md:text-45pt font-normal text-black capitalize md:leading-[2.55rem]"
                  : "hidden"
              }`}
            >
              {subtitle}
            </h3>
            <h2
              className="text-2xl leading-tight md:text-45pt 
            font-bold text-primary md:leading-[2.55rem] md:w-[82%]"
            >
              {title}
            </h2>
          </div>
          <div className="flex flex-col gap-2">
            <p
              className="text-justify text-base md:text-15pt
              w-full leading-relaxed md:leading-loose md:w-[90%]"
            >
              {description}
            </p>
            <p
              className={`${
                description2 ? "text-tinybase" : "text-15pt"
              } w-full leading-relaxed md:leading-loose md:w-[90%]`}
            >
              {description2}
            </p>
          </div>
          <div>
            {showInputs && (
              <>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    mobile: "",
                    designation: "",
                    organization: "",
                  }}
                  validationSchema={catalogSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="mt-2 mb-8 grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div>
                          <label htmlFor="name" className="sr-only">
                            Name
                          </label>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            className="w-full px-4 py-2 text-slate-800
                border border-slate-400 rounded-md focus:outline-none"
                            placeholder="Name"
                            autoComplete="name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="email" className="sr-only">
                            Email
                          </label>
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            className="w-full px-4 py-2 text-slate-800 border border-slate-400 rounded-md focus:outline-none"
                            placeholder="Email"
                            autoComplete="email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="mobile" className="sr-only">
                            Mobile
                          </label>
                          <Field
                            type="text"
                            name="mobile"
                            id="mobile"
                            placeholder="Mobile"
                            className="w-full px-4 py-2 text-slate-800 border border-slate-400 
                            rounded-md focus:outline-none"
                            autoComplete="tel"
                          />
                          <ErrorMessage
                            name="mobile"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="designation" className="sr-only">
                            Designation
                          </label>
                          <Field
                            type="text"
                            name="designation"
                            id="designation"
                            placeholder="Designation"
                            className="w-full px-4 py-2 text-slate-800 border border-slate-400 
                            rounded-md focus:outline-none"
                            autoComplete="organization-title"
                          />
                          <ErrorMessage
                            name="designation"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>

                        <div>
                          <label htmlFor="organization" className="sr-only">
                            Organization
                          </label>
                          <Field
                            type="text"
                            name="organization"
                            id="organization"
                            placeholder="Organization Name"
                            className="w-full px-4 py-2 text-slate-800 border border-slate-400 
                                rounded-md focus:outline-none"
                            autoComplete="organization"
                          />
                          <ErrorMessage
                            name="organization"
                            component="div"
                            className="text-red-600 text-sm"
                          />
                        </div>
                      </div>

                      <div className="mt-2 grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-4">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-secondary"
                        >
                          {/* Submit to Download */}
                          {loading ? (
                            <ClipLoader size={20} color={"#14467b"} />
                          ) : (
                            buttonTxt || "Submit to Download"
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <ToastContainer />
              </>
            )}
            {buttonTxt && !showInputs && (
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-4 xs:flex-row">
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    onClick={(e: any) => {
                      btnClickEventMethod(e, buttonTxt, title, subtitle);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    {buttonTxt}
                  </button>
                </div>
                {timings ? (
                  <div className="text-[0.87rem] flex flex-col gap-1">
                    <div className="tracking-wider">+91 - 8106165888</div>
                    <div className="">Timings: {timings}</div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageTextLayout;
