import React from "react";
import HeaderImg from "../../assets/images/clearviz-lenses/lens-portfolio/bifocalrx/headerImg.webp";
import BiFocalRxImg from "../../assets/images/clearviz-lenses/lens-portfolio/bifocalrx/Bifocal1.webp";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import { ImageTextLayout, SEO } from "../../components";
import UrlConstants from "../../constants/urlConstants";
import { NavigationStrings } from "../../constants";

const BiFocalRx = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.LensPortfolio.BIFOCALRX);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Clearviz Bi Focal Rx Lenses | Dual-Prescription Lenses for Near and Far Vision",
    "description": "Explore Clearviz Bi Focal Rx lenses, offering two distinct optical powers for both near and far vision correction. Ideal for those who need help with both distance and close-up tasks.",
    "url": UrlLink
  };
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clearviz Bi Focal Rx Lenses | Dual-Prescription Lenses for Near and Far Vision"
        description="Explore Clearviz Bi Focal Rx lenses, offering two distinct optical powers for both near and far vision correction. Ideal for those who need help with both distance and close-up tasks."
        keywords="Clearviz, Bi Focal Rx lenses, prescription lenses, near vision, distance vision, dual-prescription lenses"
        name="Clearviz Bi Focal Rx Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[35vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={HeaderImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clearviz Bi Focal Rx
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          Clearviz RX Bi Focal lenses are prescription lenses that have two
          distinct optical powers to correct vision at different distances. They
          are designed for those who need help with both near and far vision,
          typically due to presbyopia, an age-related condition that affects the
          eye&apos;s ability to focus on close objects. They provide clear
          vision for both distance and near tasks within one pair of glasses,
          eliminating the need to switch between different pairs of glasses.
        </p>

        {/* 3 Column */}
        <div className="py-8 px-custom-padding w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-2 md:justify-between">
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc tracking-wide ml-4 text-base">
                Popular choice for those who require a simple and effective
                solution for their vision needs at multiple distances.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">Benefits</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc tracking-wide ml-4 text-base">
                <span className="font-semibold text-black">Convenience: </span>
                They combine two prescriptions in one lens, which means you
                don&apos;t have to carry multiple pairs of glasses.
              </li>
              <li className="list-disc tracking-wide ml-4 text-base">
                <span className="font-semibold text-black">
                  Cost-Effective:{" "}
                </span>
                They can be more economical than purchasing two separate pairs
                of glasses or investing in more advanced progressive lenses.
              </li>
              <li className="list-disc tracking-wide ml-4 text-base">
                <span className="font-semibold text-black">
                  Ease of Adaptation:{" "}
                </span>
                The visible lines on the lenses can help users know where to
                look to see clearly at different distances.
              </li>
              <li className="list-disc tracking-wide ml-4 text-base">
                <span className="font-semibold text-black">Versatility: </span>
                They provide a wider lens area for reading, making activities
                like reading or working on a computer more comfortable.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* img */}
      <div className="pt-4 pb-8 px-custom-padding grid grid-cols-1 place-items-center">
        <div className="md:w-[800px] md:h-[440px]">
          <img
            src={BiFocalRxImg}
            alt="Bi focal Rx"
            className="w-[100%] h-[100%] object-cover"
          />
        </div>
      </div>

      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default BiFocalRx;
