import React from "react";
import HeaderImg from "../../assets/images/clearviz-lenses/lens-portfolio/drive/headerImg.webp";
import Drive from "../../assets/images/clearviz-lenses/lens-portfolio/drive/ClearViz Drive.webp";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import { ImageTextLayout, SEO } from "../../components";
import UrlConstants from "../../constants/urlConstants";
import { NavigationStrings } from "../../constants";
const ClearvizDrive = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.LensPortfolio.DRIVE);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Clearviz Drive Lenses | Perfect Vision for Driving",
    "description": "Clearviz Drive lenses are designed for drivers, featuring a night vision zone that enhances visual acuity and minimizes eye fatigue for safe driving.",
    "url": UrlLink
  };
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clearviz Drive Lenses | Perfect Vision for Driving"
        description="Clearviz Drive lenses are designed for drivers, featuring a night vision zone that enhances visual acuity and minimizes eye fatigue for safe driving."
        keywords="Clearviz Drive, driving lenses, night vision, vision correction, progressive lenses, single vision"
        name="Clearviz Drive Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />

      <div className="relative w-full h-[35vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={HeaderImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clearviz Drive
        </h1>
        <div className="flex flex-col gap-2">
          <p className="text-base text-black leading-loose w-full md:w-[98%]">
            Tailored free-form progressive lenses designed specifically for
            drivers, featuring a specific night vision zone. Clearviz Drive lens
            is well-suited for driving due to its customized power distribution.
            The night vision zone in this personalized free-form progressive
            lens addresses refractive differences between day and night,
            enhancing visual acuity and minimizing eye fatigue.
          </p>
          <p className="text-base text-black leading-loose w-full md:w-[98%]">
            Clearviz Drive Single Vision lenses feature a dedicated night vision
            zone, ensuring comfortable and secure driving. Clearviz Drive Single
            Vision lenses incorporate a night vision zone to compensate for the
            refractive differences between day and night, resulting in
            impeccable visual acuity and reduced eyestrain.
          </p>
        </div>
      </div>
      {/* img */}
      <div className="pt-4 pb-8 px-custom-padding grid grid-cols-1 place-items-center">
        <div className="md:w-[800px] md:h-[440px]">
          <img src={Drive} alt="ClearvizDrive" className="w-[100%] h-[100%]" />
        </div>
      </div>

      {/* Ideal Wearer */}
      <div className="py-8 px-custom-padding w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 md:justify-between">
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-bold text-primary">Ideal Wearer</p>
          <ul className="w-full md:w-[90%] flex flex-col gap-4">
            <li className="list-disc tracking-wide ml-4 text-base">
              Drivers looking for high-quality and comfortable progressive
              lenses.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              Drivers who have the need to drive at night and are searching for
              a safer solution that corrects night myopia.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              Single-vision users who are looking for an option designed for
              driving moments.
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-bold text-primary">Benefits</p>
          <ul className="w-[90%] flex flex-col gap-4">
            <li className="list-disc tracking-wide ml-4 text-base">
              Improved vision while driving.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              A safe option for the night drive, due to a special zone that
              compensates for and corrects the night myopia effects.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              Optimized near vision to provide a better focus on the dashboard
              and mirrors.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              Easy focus and agile eye movement.
            </li>
            <li className="list-disc tracking-wide ml-4 text-base">
              Available for all types of prescriptions and additional powers.
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">
              MFH (Minimum Fitting Height)
            </p>
            <ul className="w-full flex flex-col gap-4">
              <li className="list-disc tracking-wide ml-4 text-base">
                <div>18 mm</div>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-2xl font-bold text-primary">Personalization</p>
            <ul>
              <li className="list-disc tracking-wide ml-4 text-base">
                Personalized Progressive Lens
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default ClearvizDrive;
