import HeaderImg from "../../assets/images/clearviz-lenses/lens-coating/castle-blue-coating/headerImg.webp";
import CastleBlueCoating1 from "../../assets/images/clearviz-lenses/lens-coating/castle-blue-coating/castlebluecoating2.webp";
import CastleBlueCoating2 from "../../assets/images/clearviz-lenses/lens-coating//castle-blue-coating/castlebluecoating1.webp";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import { ImageTextLayout, SEO } from "../../components";
import UrlConstants from "../../constants/urlConstants";
import { NavigationStrings } from "../../constants";

const BlueUV = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.LensCoatinOptions.BLUEUV);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Clearviz Blue UV ++ | Advanced Blue Light Protection",
    "description": "Discover Clearviz Blue UV ++, an advanced blue light filter that reduces eye strain, enhances sleep quality, and protects your long-term eye health.",
    "url": UrlLink
  };
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clearviz Blue UV ++ | Advanced Blue Light Protection"
        description="Discover Clearviz Blue UV ++, an advanced blue light filter that reduces eye strain, enhances sleep quality, and protects your long-term eye health."
        keywords="Clearviz, Blue UV ++, blue light filter, blue light protection, eye comfort, sleep quality, eye health"
        name="Clearviz Blue UV ++"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="Potents coating header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 md:space-y-6 px-custom-padding">
        <div>
          <h1 className="text-2xl md:text-3xl text-primary font-bold">
            Blue UV ++ (Advance Blue Light Filter)
          </h1>
          <h4 className="font-medium text-lg md:text-xl text-primary py-2">
            See the world in a new light
          </h4>
        </div>
        <div className="grid grid-cols-1 gap-12 md:gap-20">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2 md:gap-4">
            <div className="flex flex-col gap-6">
              <p className="text-15pt text-black leading-loose w-[98%]">
                Blue light is a high-energy wavelength emitted by digital
                screens, LED lighting, and sunlight. While some blue light
                exposure is natural and necessary for regulating sleep-wake
                cycles and mood, overexposure, especially from artificial
                sources, can lead to eye strain, fatigue, and potential
                long-term damage to retinal cells.
              </p>
              <p className="text-15pt text-black leading-loose w-[98%]">
                Blue light filtration is achieved through the inclusion of
                specific materials or additives or filters in the coating
                layers. These materials are designed to selectively absorb or
                reflect blue light while allowing other wavelengths of light,
                including beneficial light like green and red wavelengths, to
                pass through. The precise composition and design of the coating
                are engineered to maintain optical clarity while effectively
                blocking harmful blue light.
              </p>
            </div>
            <div className="w-full md:w-2/4 mx-auto">
              <img src={CastleBlueCoating1} alt="Blue UV ++ 1" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col gap-12">
              <div className="flex flex-col gap-4">
                <h1 className="text-3xl text-primary font-bold">
                  What it does
                </h1>
                <div className="flex flex-col gap-4">
                  <p className="text-base text-black leading-loose w-[98%]">
                    <strong className="text-primary">
                      Blue light filtration:{" "}
                    </strong>
                    Blue UV ++ selectively filters out a portion of the blue
                    light spectrum, particularly the high-energy, potentially
                    harmful blue-violet wavelengths. By reducing the amount of
                    blue light that reaches the eyes, it helps alleviate digital
                    eye strain, improve sleep quality, and protect against
                    potential retinal damage associated with prolonged blue
                    light exposure.
                  </p>
                </div>
              </div>
              <div className="w-full md:hidden">
                <img src={CastleBlueCoating2} alt="Castle Blue Coating 2" />
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="text-3xl text-primary font-bold">Benefits</h1>
                <div className="flex flex-col gap-4">
                  <p className="text-base text-black leading-loose w-[98%]">
                    <strong className="text-primary">Eye comfort: </strong>
                    By filtering out harmful blue light, it helps to reduce eye
                    strain, fatigue, and discomfort associated with prolonged
                    screen time or exposure to artificial lighting.
                  </p>
                  <p className="text-base text-black leading-loose w-[98%]">
                    <strong className="text-primary">Sleep quality: </strong>
                    Blue light exposure, particularly in the evening, can
                    disrupt the body’s natural sleep-wake cycle by suppressing
                    the production of melatonin, a hormone responsible for
                    regulating sleep. By filtering out excessive blue light,
                    especially from electronic devices, it promotes better sleep
                    quality and overall well-being.
                  </p>
                  <p className="text-base text-black leading-loose w-[98%]">
                    <strong className="text-primary">
                      Long-term eye health:{" "}
                    </strong>
                    Protection against excessive blue light exposure may help
                    mitigate the risk of developing age-related macular
                    degeneration (AMD) and other retinal disorders associated
                    with prolonged blue light exposure. By investing in lenses
                    with blue light protection, customers can proactively
                    safeguard their eye health and reduce the potential for
                    long-term damage.
                  </p>
                  <p className="text-base text-black leading-loose w-[98%]">
                    <strong className="text-primary">
                      Visual performance:{" "}
                    </strong>
                    Overall improved visual experience for the wearer.
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden md:block md:w-2/4 md:mx-auto">
              <img src={CastleBlueCoating2} alt="Blue UV ++ 2" />
            </div>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default BlueUV;
