import React from "react";
import HeaderImg from "../../assets/images/clearviz-lenses/lens-portfolio/digifocus/headerImg.webp";
import DigiFocus from "../../assets/images/clearviz-lenses/lens-portfolio/digifocus/ClearViz Digi Focus.webp";
import DigiFocusStandard from "../../assets/images/clearviz-lenses/lens-portfolio/digifocus/DigiFocus-Standard.webp";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import { ImageTextLayout, SEO } from "../../components";
import UrlConstants from "../../constants/urlConstants";
import { NavigationStrings } from "../../constants";

const ClearvizDigiFocus = () => {
  const UrlLink = UrlConstants.base_url.concat(NavigationStrings.LensPortfolio.DIGIFOCUS);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Clearviz Digi Focus Lenses | Relieve Eye Strain",
    "description": "Clearviz Digi Focus lenses are designed to relieve eye strain from prolonged digital device use. Ideal for professionals who perform close-up tasks.",
    "url": UrlLink
  };  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clearviz Digi Focus Lenses | Relieve Eye Strain"
        description="Clearviz Digi Focus lenses are designed to relieve eye strain from prolonged digital device use. Ideal for professionals who perform close-up tasks."
        keywords="Clearviz Digi Focus, eye strain relief, digital lenses, vision correction, eyewear, digital devices"
        name="Clearviz Digi Focus Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[35vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={HeaderImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clearviz Digi Focus
        </h1>
        <p className="text-base text-black leading-loose w-full md:w-[98%]">
          Clearviz Digi Focus lenses are designed to relieve eye strain and
          discomfort from prolonged close-up tasks like reading, writing, or
          using digital devices. These lenses can be worn by remote workers,
          office workers, health professionals, industrial workers, and many
          other professionals.
        </p>
      </div>
      {/* img */}
      <div className="pt-4 pb-8 px-custom-padding grid grid-cols-1 gap-8 md:grid-cols-2 place-items-center">
        <div className="px-6 flex flex-col gap-4">
          <h3 className="uppercase text-primary font-semibold text-lg">
            Clearviz DigiFocus Lens
          </h3>
          <div>
            <img
              src={DigiFocus}
              alt="ClearvizDigiFocus"
              className="w-[100%] h-[100%]"
            />
          </div>
        </div>
        <div className="px-6 flex flex-col gap-4">
          <h3 className="uppercase text-primary font-semibold text-lg">
            Standard Lens
          </h3>
          <div>
            <img
              src={DigiFocusStandard}
              alt="Digi Focus StandardLenses"
              className="w-[100%] h-[100%]"
            />
          </div>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default ClearvizDigiFocus;
